<template>
  <b-modal
    id="popup-sharetarget"
    :visible="shallShowShareTargetPopup"
    footer-class="d-flex justify-content-between"
    size="xl"
    centered
    no-close-on-backdrop
    @change="(val) => $emit('update:shall-show-sharetarget-popup', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ titleHeaderPopup }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="$emit('update:shall-show-sharetarget-popup', false)"
        />
        <!-- <feather-icon
          icon="Maximize2Icon"
          class="ml-1 cursor-pointer"
        /> -->
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="onDiscard()"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          variant="info"
          right
          @click="onCopy"
        >
          {{ $t('Copy') }}
        </b-button>
      </div>

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="onDiscard()"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <div>
      <div>
        <b-card style="margin-bottom: 0.6rem;">
          <div>
            <b-row>

              <!-- Field: Message -->
              <b-col
                cols="12"
              >
                <v-jsoneditor
                  ref="refMessage"
                  v-model="popupData.message"
                  :options="optionsMessage"
                  :plus="false"
                  height="500px"
                />
              </b-col>

            </b-row>
          </div>
        </b-card>
      </div>
    </div>

  </b-modal>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import VJsoneditor from 'v-jsoneditor'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,

    FeatherIcon,
    VJsoneditor,
  },
  model: {
    prop: 'shallShowShareTargetPopup',
    event: 'update:shall-show-sharetarget-popup',
  },
  props: {
    shallShowShareTargetPopup: {
      type: Boolean,
      required: true,
    },
    titleHeaderPopup: {
      type: String,
      default: '',
    },
    popupData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsMessage: {
        mode: 'code',
        mainMenuBar: true,
        enableSort: false,
        enableTransform: false,
        statusBar: false,
      },
    }
  },
  methods: {
    onDiscard() {
      this.$emit('discard-sharetarget-popup')
      this.$emit('update:shall-show-sharetarget-popup', false)
    },
    async onCopy() {
      try {
        await navigator.clipboard.writeText(JSON.stringify(this.popupData.message))
        const okMsg = this.$i18n.t('Copy on successfully')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: okMsg,
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        },
        {
          position: 'bottom-right',
        })
      } catch ($e) {
        const errorMsg = `${this.$i18n.t('Copy on error')}\n${$e.message}`
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorMsg,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
.jsoneditor-poweredBy {
  display: none !important;
}
.jsoneditor {
  border: thin solid #d8d6de !important;
}
.jsoneditor-menu{
  background-color: #aaaaaa !important;
  border-bottom: 1px solid #aaaaaa !important;
}
</style>
