import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultShareTargetTransaction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lineapi/get_default_sharetarget_transaction', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListShareTargetTransaction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_list_sharetarget_transaction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getShareTargetTransaction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_sharetarget_transaction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteShareTargetTransaction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/delete_sharetarget_transaction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    exportShareTargetTransaction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/export_sharetarget_transaction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
