<template>
  <b-modal
    id="export-sharetarget-transaction"
    :visible="shallShowShareTargetTransactionExportModal"
    :title="`${$t('Export')} ${$t('ShareTargetTransaction')}`"
    footer-class="d-flex justify-content-between"
    size="lg"
    centered
    no-close-on-backdrop
    @change="(val) => $emit('update:shall-show-sharetarget-transaction-export-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ `${$t('Export')} ${$t('ShareTargetTransaction')} (${$t('Excel File')})` }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="(busy === false) ? $emit('update:shall-show-sharetarget-transaction-export-modal', false) : false"
        />
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          right
          :disabled="busy"
          @click="onSubmit"
        >
          {{ $t('Export Data') }}
        </b-button>
      </div>

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >

      <div>
        <b-form
          ref="refForm"
          class="position-relative"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <div>
            <b-card style="margin-bottom: 0.6rem;">
              <div>
                <b-row>

                  <!-- Field: File Name -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      :label="$t('File Name')"
                      label-for="sharetarget-transaction-file-name"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('File Name')"
                        rules="required"
                      >
                        <b-form-input
                          id="sharetarget-transaction-file-name"
                          v-model="file_name"
                          :state="getValidationState(validationContext)"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Sheet Name -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      :label="$t('Sheet Name')"
                      label-for="sharetarget-transaction-sheet-name"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Sheet Name')"
                        rules="required"
                      >
                        <b-form-input
                          id="sharetarget-transaction-sheet-name"
                          v-model="sheet_name"
                          :state="getValidationState(validationContext)"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>
                  </b-col>

                </b-row>
                <b-row>

                  <!-- Field: Share Target -->
                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      :label="$t('Share Target')"
                      label-for="sharetarget-transaction-sharetarget"
                    >
                      <v-select
                        v-model="exportData.sharetarget_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="shareTargetOptions"
                        :reduce="val => val.value"
                        :clearable="true"
                        input-id="sharetarget-transaction-sharetarget"
                        multiple
                        chips
                      />
                    </b-form-group>
                  </b-col>

                </b-row>
                <b-row>

                  <!-- Field: Share Date -->
                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      :label="$t('Share Date')"
                      label-for="sharetarget-transaction-share-date"
                    >
                      <flat-pickr
                        id="sharetarget-transaction-share-date"
                        v-model="exportData.create_date"
                        :config="{
                          mode: 'range',
                          allowInput: true,
                          dateFormat: 'Y-m-d',
                          maxDate: 'today',
                          altInput: true,
                          altFormat: 'd F Y',
                        }"
                        class="form-control"
                        :placeholder="$t('Share Date')"
                      />
                    </b-form-group>
                  </b-col>

                </b-row>
              </div>
            </b-card>
          </div>

        </b-form>

      </div>

    </validation-observer>

    <b-overlay
      :show="busy"
      no-wrap
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton, BForm,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BOverlay,
  BProgress,
  BCardText,
  BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  onUnmounted,
} from '@vue/composition-api'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import vSelect from 'vue-select'
import XLSX from 'xlsx'
import flatPickr from 'vue-flatpickr-component'
import storeShareTargetTransaction from './storeShareTargetTransaction'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BForm,
    BFormGroup,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BProgress,
    BCardText,
    vSelect,
    BFormInput,
    flatPickr,

    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
  },
  model: {
    prop: 'shallShowShareTargetTransactionExportModal',
    event: 'update:shall-show-sharetarget-transaction-export-modal',
  },
  props: {
    shallShowShareTargetTransactionExportModal: {
      type: Boolean,
      required: true,
    },
    shareTargetOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      exportData: {
        create_date: [],
      },
      sheet_name: 'ShareTargetTransaction',
      file_name: 'sharetarget-transaction.xlsx',
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
    }
  },
  methods: {
    onShown() {
      this.$refs.dialog.focus()
    },
    onHidden() {
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      store.dispatch('sharetarget-transaction-store/exportShareTargetTransaction', this.exportData)
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.busy = false
          this.processing = false

          if (response.data.length > 0) {
            try {
              const ws = XLSX.utils.aoa_to_sheet(response.data)
              const wb = XLSX.utils.book_new()
              XLSX.utils.book_append_sheet(wb, ws, this.sheet_name)
              XLSX.writeFile(wb, this.file_name)
            } catch (error) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
          }

          this.$emit('sharetarget-transaction-export-update')
          this.$emit('update:shall-show-sharetarget-transaction-export-modal', false)
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.busy = false
          this.processing = false
        })
    },
    onDiscard() {
      this.$emit('discard-sharetarget-transaction-export')
      this.$emit('update:shall-show-sharetarget-transaction-export-modal', false)
    },
  },
  setup() {
    if (!store.hasModule('sharetarget-transaction-store')) store.registerModule('sharetarget-transaction-store', storeShareTargetTransaction)

    onUnmounted(() => {
      if (store.hasModule('sharetarget-transaction-store')) store.unregisterModule('sharetarget-transaction-store')
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    return {
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
</style>
